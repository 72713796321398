.pagetop {
    @include mq-up(md) {
        display: block;
    }
    max-width: $container-width;
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    &__link {
        @include mq-down(md) {
            display: none;
        }
        position: absolute;
        top: -80px;
        display: block;
        margin: auto;
        transition: 0.6s;
        right: -80px;
        @include mq-down(xxx) {
            right: 6%;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}