.boxDesign{
	padding: 10px;
	background-color: #fff;
	@include mq-down(md){
		padding: 5px;
	}
	&__inner{
		border: 1px solid $main-color;
		padding: 5px;
		@include mq-up(md){
			padding: 30px;
		}
		&--large{
			@include mq-down(md){
				padding: 15px;
			}
			@include mq-up(md){
				padding: 60px 60px 70px ;
			}
		}
	}
}