

.loading{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	z-index: 1000;
}

.wrapper{
	overflow: hidden;
	font-size: 1.4rem;
	padding: 0 10px;
	@include mq-down(md){
		padding: 0 5px;
	}
	@include mq-up(md){
		font-size: 1.6rem;
	}
	&::after,&::before{
		content: "";
		display: block;
		position: fixed;
		height: 0;
		z-index: 1000;
		transition: height 1s;
	}
	&::after{
		border-left: solid 10px $main-color;
		left: 0;
		bottom: 0;
		@include mq-down(md){
			border-left: solid 5px $main-color;
		}
	}
	&::before{
		border-right: solid 10px $main-color;
		top: 0;
		right: 0;
		@include mq-down(md){
			border-right: solid 5px $main-color;
		}
	}
}

.frame{
	position: fixed;
	border-top: solid 10px $main-color;
	width: 0;
	transition: width 1s;
	@include mq-down(md){
		border-top: solid 5px $main-color;
	}
	z-index: 1000;
	&--top{
		top: 0;
		left: 0;
	}
	&--bottom{
		bottom: 0;
		right: 0;
	}
}

.line{
	&::after,&::before{
		height: 100%;
	}
	.frame{
		width: 100%;
	}
}

.section{
	padding: 3em 0;
	&__concept{
		padding: 10% 0 9em;
	}
	&__profile{
		background-image: url(../images/bg_profile.jpg);
		background-size: 100%;
		background-position: bottom center;
		padding-bottom: 25%;
	}
	&__history{
		margin: auto;
		padding: 4em 0;
	}
	&__link{
		padding: 3em 0 35%;
		@include mq-up(md){
			padding: 3em 0 26%;
		}
		background-image: url(../images/img_contact.png);
		background-size: 100%;
		background-position: center bottom;
	}
	// &__service{
	// }
	&__media{
		@include mq-down(md){
			padding: 0 0 8em;
		}
	}
	&__about{
		padding-top: 0;
		color: #fff;
	}
	&__form{
		max-width: 680px;
		margin: auto;
		@include mq-down(md){
			padding: 3em 5%;
		}
	}
}


.fade-show{
	transition: .9s;
	opacity: 0;
	&.show{
		opacity: 1;
	}
}