.header {
    padding-bottom: 1%;
    position: relative;
    @include mq-down(md) {
        padding-bottom: 3%;
    }
    &--in {
        position: fixed;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 995;
        background-color: #fff;
        transition: top 0.8s;
        top: -100%;
        padding: 0 5px;
        padding-bottom: 0.9rem;
        &.active {
            top: 0;
        }
    }
    &__line {
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        .header--in & {
            display: none;
        }
    }
    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0 0;
        margin: auto;
        max-width: $container-width;
        width: 90%;
        @include mq-down(md) {
            align-items: center;
        }
        @include mq-up(md) {
            width: 94%;
            padding: 40px 0 15px;
        }
        .header--in & {
            width: 94%;
            padding: 20px 0 0;
            @include mq-up(md) {
                display: none;
            }
        }
    }
    &__nav {
        margin: auto;
        max-width: $container-width;
        width: 94%;
    }
    &__logo {
        @include mq-down(md) {
            width: 60%;
            margin-top: auto;
        }
    }
    &__banner {
        display: none;
        @include mq-up(md) {
            display: block;
            max-width: 220px;
            margin-top: -40px;
        }
        .header--in & {
            margin-top: -20px;
        }
    }
    &__meta {
        @include mq-down(md) {
            width: 30%;
        }
    }
}

.logo {
    display: inline-block;
}