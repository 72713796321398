.service{
	line-height: 1.6em;
	color: $text-color;
	@include mq-up(md){
		font-size: 1.4rem;
	}
	&__img{
		margin-bottom: 1em;
		@include mq-down(md){
			margin: 0 auto 1.5em;
		}
	}
	&__item{
		color: #4a4229;
	}
}