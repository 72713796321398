.mapArea{
	width: 100%;
	min-height: 300px;
	@include mq-down(md){
		margin-bottom: 40px;
	}
	@include mq-up(md){
		min-height: 400px;
	}
	iframe{
		width: 100%;
	}
}