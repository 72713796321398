.hoverBlock{
	position: relative;
	width: 100%;
	max-width: 670px;
	&:hover .hoverBlock__content{
		opacity: 1;
	}
	&__content{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 1em;
		background-color: rgba(44,122,85,0.8);;
		color: #fff;
		text-align: left;
		transition: 0.2s;
		@include mq-down(xl){
			font-size: 1em;
		}
		@include mq-down(md){
			font-size: 1rem;
		}
		@include mq-up(xl){
			opacity: 0;
			padding: 1.5em;
		}
	}
}