.container {
	max-width: $container-width;
	margin: auto;
	width: 84%;
	@include mq-up(xxx) {
		width: 100%;
	}
	&--mini {
		max-width: $container-width - 40;
		margin: auto;
	}
	&--full {
		padding: 20px 0;
	}
}