.blogArea {
	@include mq-down(md){
		margin-top: 2.7rem;
	}
	&__text{
		margin: 1em 0 1em;
		color: $text-color;
	}
	&__btn{
		margin: 3rem auto 0;
		width: 160px;
		@include mq-down(md){
			margin: 0.8em auto;
		}
	}
}