// 準備中
.comingSoon {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	&__inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom:0;
		left:0;
		margin: auto;
		width: 50%;
		height: 50%;
		text-align: center;
		font-size: 2rem;
	}
}
