.info{
	padding: 3% 0;
	&__img{
		max-width: 470px;
		@include mq-down(md){
			margin-bottom: 3em;
		}
		@include mq-up(md){
			margin-left: auto;
		}
	}
	&__content{
		@include mq-up(xxx){
			padding-left: 1em;
		}
	}
	&__title{
		margin-bottom: 3rem;
	}
	&__postNumber{
		margin-bottom: 0.2em;
		@include mq-up(md){
			font-size: 1.8rem;
		}
	}
	&__address{
		margin-bottom: 0.2em;
		@include mq-up(md){
			font-size: 1.8rem;
		}
	}
	&__tel{
		margin-bottom: 0.2em;
		color: #fff;
		@include mq-up(md){
			font-size: 1.8rem;
		}
	}
	&__mail{
		margin-bottom: 0.2em;
		@include mq-up(md){
			font-size: 1.8rem;
		}
	}
	&__link{
		color: #fff;
		text-decoration: underline;
	}
	&__name{
		margin-top: 2em;
		@include mq-up(md){
			font-size: 2rem;
		}
	}
	&__btn{
		margin-top: 2em;
		display: block;
		max-width: 150px;
		@include mq-up(md){
			max-width: 300px;
		}
	}
}