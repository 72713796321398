.banner{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	max-width: 680px;
	padding: 1em 0;
	background-image: url(../images/banner_link.jpg);
	background-size: cover;
	color: #fff;
	text-align: center;
	transition: 0.6s;
	font-size: 1.8rem;
	@include mq-down(md){
		margin: 0 5%;
	}
	@include mq-up(md){
		font-size: 2rem;
		font-size: 3.7rem;
		padding: 0.8em 0;
	}
	&:hover{
		opacity: 0.6;
	}
}