.facebookArea {
	background-color: #fff;
	width: 100%;
	max-width: 500px;
	height: 90.5%;
	iframe{
		width: 100%;
		max-width: 100%;
		height: 100%;
	}
}