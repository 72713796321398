.sns {
    display: flex;
    &__item {
        padding: 0 0.6em 0 0;
        @include mq-down(md) {
            padding: 0 0.3em 0 0.3em;
            font-size: 3.2rem;
        }
        @include mq-up(md) {
            padding: 0 0.6em;
            font-size: 2rem;
            &:nth-child(1) {
                position: relative;
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    border-right: solid 1px $main-color;
                    display: block;
                    content: "";
                    height: 1.8rem;
                }
            }
        }
    }
    &__link {
        color: $main-color;
        display: flex;
    }
    &__icon {
        fill: $main-color;
        @include mq-up(md) {
            margin-right: 0.5em;
        }
    }
    @include mq-down(lg) {
        &__text {
            display: none;
            width: 0;
            overflow: hidden;
        }
    }
}