.spFloatingSpacet {
    display: none;
    @include mq-down(md) {
        display: flex;
        position: fixed;
        bottom: 5px;
        left: 0;
        right: 0;
        z-index: 100;
    }
}