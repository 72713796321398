﻿
// 変数の定義
$main-color: #008e4c;
$sub-color: #2c7a55;
$bass-color: #eee;
$text-color: #4a4229;
$text-color2: #9e7e5f;
// 960 1040 1120 1200 1280
$container-width:1400px;

// breakpoint
$screen-size:( xs:400,
sm:500,
md:768,
lg:1000,
xl:1200,
xx:1400,
xxx:1600,
)!default;


html {
	font-size: 10px;
	word-wrap: break-word;
	font-family: arial, sans-serif;
}

div,
section,
article,
nav,
aside,
{
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
	font-size: 1rem;
	font-weight: normal;
	margin: 0;
	display: block;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

li {
	margin: 0;
	padding: 0;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
}

svg{
	height: 100%;
	width: 100%;
}

video{
	width: 100%;
    max-width: 100%;
    height: auto;
}



// @mixinの定義
@mixin mq-up($breakpoint: md) {
	$screen-size: #{map-get($screen-size, $breakpoint)}px;
	@media screen and (min-width: #{$screen-size}) {
		@content;
	}
}

@mixin mq-down($breakpoint: md) {
	$screen-size: #{map-get($screen-size, $breakpoint)-1}px;
	@media screen and (max-width: #{$screen-size}) {
		@content;
	}
}

// responsive
@mixin res-up($class, $bp: $screen-size) {
	#{$class} {
		@content;
	}
	@each $suffix,
	$value in $bp {
		$value: #{$value}px;
		@media screen and (min-width: #{$value}) {
			#{$class}-#{$suffix} {
				@content;
			}
		}
	}
}

@mixin res-down($class, $bp: $screen-size) {
	#{$class} {
		@content;
	}
	@each $suffix,
	$value in $bp {
		$value: #{$value}px;
		@media screen and (max-width: #{$value}) {
			#{$class}-#{$suffix} {
				@content;
			}
		}
	}
}

// グリットデザイン
@mixin column($maxcol: 12,$col:1) {
    width: 100% / $maxcol * $col;
    width: calc(100% / #{$maxcol} * #{$col});
}
@mixin grid($gridName, $maxcol:12, $garter:0) {
    .#{$gridName} {
        display: flex;
        flex-wrap: wrap;
        margin-left:-#{$garter};
        &--right {
            justify-content: flex-end;
        }
        &--center {
            justify-content: center;
        }
        &--bottom {
            align-items: flex-end;
        }
        &--align-center {
            align-items: center;
        }
    }
    .#{$gridName}__item {
        float: left; // margin: 0 $grid-garter;
        padding-left: $garter;
        @include column($maxcol);
    }
    @for $i from 1 through 9{
		.#{$gridName}--mb#{$i}{
			margin-bottom: -#{$i}rem;
			& > .#{$gridName}__item{
				margin-bottom: #{$i}rem;
			}
		}
    }

    @for $i from 1 to $maxcol+1 {
        .#{$gridName}.cols-#{$i} {
            & > .#{$gridName}__item {
                @include column($maxcol,$i);
            }
        }
        .col-#{$i} {
            @include column($maxcol,$i);
        }
    }

    @each $suffix,
    $value in $screen-size {
        $value: #{$value}px;
        @media screen and (min-width: #{$value}) {

			@for $i from 1 through 9{
				.#{$gridName}--#{$suffix}-mb#{$i}{
					margin-bottom: -#{$i}rem;
					& > .#{$gridName}__item{
						margin-bottom: #{$i}rem;
					}
				}
			}

            @for $i from 1 to $maxcol+1 {
                .col-#{$suffix}-#{$i} {
                    @include column($maxcol,$i);
                }
                .#{$gridName}.cols-#{$suffix}-#{$i} {
                    .#{$gridName}__item {
                        @include column($maxcol,$i);
                    }
                }
            }

        }
    }
}

// グリッドを生成
@include grid(grid, 12, 60px);
@include grid(grid2, 12, 30px);

// グリッドcss
// @mixin gridcss($maxcol:4,$garter:20px) {
//     display: flex;
//     flex-wrap: wrap;
//     overflow: hidden;
//     margin-left:-#{$garter};
//     & > *{
//         padding-left: $garter;
//         @include column($maxcol);
//     }
// }

@mixin gridcss-col( $columns, $garter, $point:md) {
    $screen-size: #{map-get($screen-size, $point)-1}px;
    @media screen and (min-width: #{$screen-size}) {
        & {
            @include column($columns);
        }
    }
}
// グリッドcssの指定方法
// .testwap {
//     @include gridcss(4,10px);
//     .testitem {
//         @include gridcss-col(4, 10px, xs);
//         @include gridcss-col(6, 10px, md);
//         @include gridcss-col(8, 10px, lg);
//         @include gridcss-col(12, 10px, xl);
//     }
// }



@include res-up(none){
	display: none;
};



@include res-up(br){
	display: block;
};

.pcNone{
	@include mq-up(md){
		display: none;
	}
}
.spNone{
	@include mq-down(md){
		display: none;
	}
}

.video {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}

@for $i from 1 through 9{
	.mb#{$i}{
		margin-bottom: #{$i}rem !important;
	}
}

// .spNav{
// 	@include mq-down(md) {
// 		display: none;
// 		.spNavToggle {
// 			display: block;
// 			position: fixed;
// 			top: 15px;
// 			right: 15px;
// 			padding: 5px 10px;
// 			border: solid 1px #333;
// 			width: 80px;
// 			background-color: $bass-color;
// 			font-size: 1em;
// 			&::before {
// 				content: "開く";
// 			}
// 			.spNavOpen &::before {
// 				content: "閉じる";
// 			}
// 		}
// 		.spNavOpen &{
// 			display: block;
// 		}
// 	}
// }



// ゆらゆら横に揺れる
// 使うときは以下を指定する
// animation: horizontal 1s ease-in-out infinite alternate;
@keyframes horizontal {
	0% {
		transform: translateX( -3px);
	}
	100% {
		transform: translateX( 0px);
	}
}

// ゆらゆら縦に揺れる
// 使うときは以下を指定する
// animation: vertical 1s ease-in-out infinite alternate;
@keyframes vertical {
	0% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY( 0px);
	}
}

@include mq-up(md){
  a[href^="tel"]{
    pointer-events: none;
  }
}