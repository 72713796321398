.headline{
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;
	@include mq-up(md){
		margin-top: 50px;
		margin-bottom: 60px;
	}
	&--white{
		.headline__ja{
			color: #fff;
		}
	}
	&__en{
		display: inline-block;
		@include mq-down(md){
			img{
				width: 50%;
				margin: auto;
			}
		}
	}
	&__ja{
		font-size: 1.2rem;
		margin-top: 1em;
		@include mq-up(md){
			font-size: 2.3rem;
		}
		line-height: 1em;
		color: $main-color;
		display: block;
	}
}

.headline2{
	margin-bottom: 15px;
	@include mq-up(md){
		margin-bottom: 30px;
	}
	&--white{
		.headline2__ja{
			color: #fff;
		}
	}
	&__en{
		display: block;
	}
	&__ja{
		margin-top: 1.8rem;
		font-size: 1.2rem;
		@include mq-up(md){
			font-size: 2.3rem;
		}
		line-height: 1em;
		color: $main-color;
		display: block;
	}
}



.headline3{
	color: #fff;
	margin-bottom: 2rem;
	line-height: 1em;
	font-size: 1.5rem;
	@include mq-up(md){
		font-size: 3.3rem;
	}
	&__img{
		display: inline-block;
		@include mq-down(md){
			height: 1em;
		}
	}
}


.headline4{
	margin-bottom: 15px;
	@include mq-down(md){
		text-align: center;
		margin-bottom: 30px;
	}
	@include mq-up(md){
		margin-bottom: 30px;
	}
	&--white{
		.headline2__ja{
			color: #fff;
		}
	}
	&__en{
		display: block;
		@include mq-down(md){
			width: 50%;
			margin: auto;
		}
	}
	&__ja{
		margin-top: 1.8rem;
		font-size: 1.4rem;
		@include mq-up(md){
			font-size: 2.3rem;
		}
		line-height: 1em;
		color: $main-color;
		display: block;
	}
}