.profile{
	&__img{
		margin-bottom: 2rem;
	}
	&__text{
		line-height: 1.5em;
		color: #4a4229;
		@include mq-up(md){
			line-height: 3.5vw;
		}
		@include mq-up(xl){
			line-height: 2vw;
		}
	}

}