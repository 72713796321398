.nav {
	font-size: 1.3rem;
	display: none;
	@include mq-up(md){
		display: block;
	}
	&__list{
		@include mq-up(md){
			display: flex;
			justify-content: space-between;
		}
	}
	&__item {
		display: block;
	}
	&__link {
		display: flex;
		justify-content: center;
		padding: 0.5em;
		color: #333;
		height: 100%;
		line-height: 1.5em;
		padding: 0 1em;
		&:hover{
			color:#aaa;
			& .nav__img::before{
				opacity: 1;
			}
		}
	}
	&__icon{
		padding-right: 0.8em;
		padding-top: 1.5em;
	}
	&__img{
		padding-top: 1.5em;
		position: relative;
		@include mq-up(md){
			&::before{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: auto;
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 10px 0 10px;
				border-color: $main-color transparent transparent transparent;
				transition: 0.8s;
				opacity: 0;
			}
		}
	}
}
