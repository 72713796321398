// ボタン
@mixin btn($padding:10px) {
	padding: #{$padding} 0;
	line-height: 1em;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	width: 100%;
}

@mixin btnGhost($color:#333) {
	color: $color;
	border: solid 1px $color;
	transition: 1s;
	&:hover {
		background-color: $color;
		color: #fff;
	}
}

@mixin btnFlat($color:#333) {
	color: #fff;
	background-color: $color;
	border: solid 1px $color;
	transition: 1s;
	&:hover {
		background-color: #fff;
		color: $color;
	}
}

// ボタン
.btnGhost {
	@include btn(0.7em);
	@include btnGhost(#fff);
	font-size: 1.4rem;
	@include mq-up(md){
		font-size: 2rem;
	}
}

.btnFlat {
	@include btn(1em);
	@include btnFlat($sub-color);
	font-size: 1.4rem;
	@include mq-up(md){
		font-size: 1.4rem;
	}
}