.concept{
	position: relative;
	&__line{
		position: absolute;
		top: -1%;
		right: 0;
		left: 0;
		z-index: 1;
		@include mq-up(md){
			top: -5%;
		}
	}
	&__dog{
		position: absolute;
		top: -3%;
		right: 0;
		margin: auto;
		display: block;
		z-index: 2;
		max-width: 180px;
		width: 25%;
		@include mq-up(md){
			max-width: 360px;
			width: 30%;
			top: -5%;
		}
		@include mq-up(lg){
			left: 0;
			top: -15%;
		}
		@include mq-up(xx){
			top: -5%;
		}
	}
	&__content{
		position: relative;
		z-index: 2;
	}
	&__text{
		line-height: 2em;
		margin-bottom: 1em;
		color:#4a4229;
		@include mq-up(lg){
			padding: 20% 0 0;
		}
	}
	.wap{
		margin-bottom: 2rem;
		@include mq-up(md){
			margin-bottom: 6rem;
		}
	}
	&__name{
		@include mq-down(md){
			width: 50%;
		}
	}
	&__pos{
		display: flex;
		flex-wrap: wrap;
	}
	&__pos1{
		position: relative;
		@include mq-down(lg){
			margin: 0 auto 5em;
		}
		@include mq-up(lg){
			margin-bottom: 5rem;
			margin-left: auto;
		}
	}
	&__flower{
		position: absolute;
		bottom: -5%;
		right: -5%;
		z-index: 1;
		@include mq-down(md){
			width: 50%;
		}
	}
	&__pos2{
		position: relative;
		@include mq-down(lg){
			margin: 0 auto 3em;
		}
		@include mq-up(md){
			margin-left: auto;
		}
	}
	&__flower2{
		position: absolute;
		bottom: -20%;
		left: -15%;
		z-index: 1;
		@include mq-down(md){
			left: -5%;
			width: 50%;
		}
	}
}