.bg {
    background-color: #97875b;
    &--light {
        background-color: #f4fae9;
        background-repeat: repeat;
    }
    &--green {
        background-color: #2c7a55;
        background-repeat: repeat;
    }
    &--ground {
        background-color: #eae5d1;
    }
}