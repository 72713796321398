// リスト
.list {
	padding: 0 0 0 2em;
	// .listStyleDisk
	&--disk {
		li {
			list-style-type: disc;
		}
	} // .listStyleCircle
	&--circle {
		li {
			list-style-type: circle;
		}
	} // .listStyleNumber0
	&--number0 {
		li {
			list-style-type: decimal;
		}
	} // .listStyleNumber1
	&--number1 {
		li {
			list-style-type: decimal-leading-zero;
		}
	} // .listStyleNumber2
	&--number2 {
		li {
			list-style-type: upper-roman;
		}
	} // .listStyleNumber3
	&--number3 {
		li {
			list-style-type: cjk-ideographic;
		}
	}
}