.thanks{
    text-align: center;
    margin-top: 120px;
    &__logo{
        display: inline-block;
        margin: auto;
    }
    &__box{
        padding: 25px;
        background-color: #e5f9f0;
        max-width: 480px;
        margin: 30px auto;
        line-height: 1.5em;
        color: #666;
        font-size: 14px;
    }
    &__message{
        font-size: 18px;
        color: #297657;
        margin-bottom: 1em;
        font-weight: bold;
        line-height: 1.5em;
    }
    &__btn{
        display: block;
        background-color: #297657;
        line-height: 1em;
        padding: 0.5em 0;
        color: #fff;
        font-size: 1.6rem;
        text-align: center;
        width: 250px;
        margin: 30px auto;
    }
}