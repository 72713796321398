.historyList {
    border-top: 1px dashed #008e4b;
    @include mq-up(md) {
        margin: 3rem 0;
        font-size: 1.7rem;
    }
    &__item {
        border-bottom: 1px dashed #008e4b;
        color: #008e4b;
        line-height: 1.5em;
        padding: 0.6em 2em;
        position: relative;
        &::before {
            position: absolute;
            top: 0.6em;
            bottom: 0;
            left: 1em;
            content: "・";
        }
    }
}