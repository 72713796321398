.product{
	&__title{
		font-size: 2rem;
		line-height: 1em;
		margin-bottom: 1em;
	}
	&__btn{
		max-width: 100%;
		margin-top: 3rem;
	}
}