.textBox{
	margin: 2.5em 0;
	@include mq-up(md){
		margin: 8em 0;
	}
	&__content{
		line-height: 1.5em;
		color: #4a4229;
		@include mq-up(md){
			line-height: 2em;
		}
	}
}